import React from 'react'
// import {
// 	FaSass,
// 	FaHtml5,
// 	FaReact,
// 	FaMobileAlt,
// 	FaRocket,
// 	FaOsi
// } from 'react-icons/fa';

import { Link } from 'gatsby'

import '../styles/styles.scss'
// import Emoji from './emoji';
import mowLogo from '../images/mow-for-community-wellness.svg'
import mowTextLeftLogo from '../images/the-mask-text-left.svg'
import iconSocial from '../images/icon-social.svg'
import iconPhysical from '../images/icon-physical.svg'
import iconEconomical from '../images/icon-economical.svg'
import getStartedSVG from '../images/get-started.svg'

import featOne from '../images/biz-30hop.jpg'
import featTwo from '../images/biz-two.jpg'
import featThree from '../images/biz-three.jpg'
import featFour from '../images/biz-four.jpg'

const Midsection = () => (
  <React.Fragment>
    <section
      className="section is-mow-border home-about-section"
      key="mow-banner-section"
    >
      <div className="container has-text-centered">
        <div className="columns">
          <div className="column is-one-third">
            <img src={mowTextLeftLogo} className="home-about-img" />
          </div>
          <div className="column is-6">
            <p className="is-spaced has-text-left home-about">
              is a <strong>Positive Empowering Approach</strong> that promotes
              responsible behavior that is necessary to keep society open during
              this pandemic while benefiting the following areas of wellness
            </p>
            <div className="level">
              <div className="level-item home-about-icon">
                <img src={iconSocial} alt="Social" width="50" height="50" />
                <span className="is-inline-block is-uppercase">Social</span>
              </div>
              <div className="level-item home-about-icon">
                <img src={iconPhysical} alt="Physical" width="50" height="50" />
                <span className="is-inline-block is-uppercase">Physical</span>
              </div>
              <div className="level-item home-about-icon">
                <img
                  src={iconEconomical}
                  alt="Economical"
                  width="50"
                  height="50"
                />
                <span className="is-inline-block is-uppercase">Economical</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section feat-biz" key="mow-featured-biz-tiles">
      <div className="container-fluid has-text-centered">
        <h3 className="title is-3">FEATURED BUSINESSES</h3>

        {/* Regular Columns */}
        <div className="columns">
          <div className="column">
            <Link to="/for-businesses/">
              <div className="card">
                <div className="card-content is-paddingless">
                  <figure className="image is-4by3">
                    <img src={featOne} />
                  </figure>
                </div>
                <footer>
                  <div className="card-footer-item feat-biz-overlay">
                    <h6 className="subtitle is-size-7 is-uppercase">
                      Restaurant
                    </h6>
                    <h4 className="title is-size-4 is-uppercase">30HOP</h4>
                  </div>
                </footer>
              </div>
            </Link>
          </div>

          <div className="column">
            <Link to="/for-businesses/">
              <div className="card">
                <div className="card-content is-paddingless">
                  <figure className="image is-4by3">
                    <img src={featTwo} />
                  </figure>
                </div>
                <footer>
                  <div className="card-footer-item feat-biz-overlay">
                    <h6 className="subtitle is-size-7 is-uppercase">Salon</h6>
                    <h4 className="title is-size-4 is-uppercase">
                      Thompson & Co. Salon
                    </h4>
                  </div>
                </footer>
              </div>
            </Link>
          </div>

          <div className="column">
            <Link to="/for-businesses/">
              <div className="card">
                <div className="card-content is-paddingless">
                  <figure className="image is-4by3">
                    <img src={featThree} />
                  </figure>
                </div>
                <footer>
                  <div className="card-footer-item feat-biz-overlay">
                    <h6 className="subtitle is-size-7 is-uppercase">
                      Restaurant
                    </h6>
                    <h4 className="title is-size-4 is-uppercase">Pagliai's</h4>
                  </div>
                </footer>
              </div>
            </Link>
          </div>

          <div className="column">
            <Link to="/for-businesses/">
              <div className="card">
                <div className="card-content is-paddingless">
                  <figure className="image is-4by3">
                    <img src={featFour} />
                  </figure>
                </div>
                <footer>
                  <div className="card-footer-item feat-biz-overlay">
                    <h6 className="subtitle is-size-7 is-uppercase">
                      Construction
                    </h6>
                    <h4 className="title is-size-4 is-uppercase">
                      Boyd Crosby Construction
                    </h4>
                  </div>
                </footer>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
    <section className="section" key="mow-banner-section-two">
      <div className="container has-text-centered">
        <div className="columns">
          <div className="column is-three-fifths is-offset-one-fifth">
            <p>
              Thanks to these businesses for helping jumpstart the cause and for
              joining our community. The ‘Mask of Wellness’ certification is
              provided to <strong>COVID-Conscious</strong> businesses that
              supports three simple measures: wear masks, attend to personal and
              surface hygiene and daily health check-in.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section
      className="section is-paddingless mow-border-top"
      key="mow-forbiz-getstarted"
    >
      <div className="container-fluid">
        <div className="columns footer-cta-section">
          <div className="column is-3 has-background-primary footer-block">
            <img src={getStartedSVG} className="footer-get-started" />
          </div>
          <div className="column is-8 sign-up-col">
            <h5 className="title is-4">SIGN UP YOUR BUSINESS</h5>
            <p>
              Our collaborative efforts WILL play a role in preventing COVID-19 reoccurrences and spikes. Consumers will seek out ‘Covid-Conscious’ Businesses thus benefiting the Health, Wealth, and Wellness of Individuals and Businesses in all of OUR communities.
            </p>
            <Link to="/get-started/">
              <button className="button">JOIN THE COMMUNITY</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  </React.Fragment>
)

export default Midsection
