import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section className="hero gradientBg is-medium">
      <div className="secondary-page hero-body is-primary is-mow-border">
        <div className="container">
          <div className="columns">
            <div className="column is-parent is-one-third">
              <div className="container">
                <h1 className="title is-3 has-text-primary">Nothing Here!</h1>
                <p>
                  There's nothing at this url, <Link to="/">go home!</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
