import React from 'react'

import Layout from '../components/layout'
import SecondaryPageHero from '../components/secondaryPageHero'

import forBizHeroImg from '../images/hero-for-businesses-salon.png'
import forBizSvg from '../images/for-businesses.svg'

import productStickerCircle from '../images/product-sticker-circle.png'
import productWindowCling from '../images/product-window-cling.png'
import productShirt from '../images/product-shirt.png'

const ProductsPage = () => (
  <Layout>
    <SecondaryPageHero
      heroImg={forBizHeroImg}
      iconImg={forBizSvg}
      iconImgAlt={'Industrial Building'}
      title={'Our'}
      accentedTitle={'Products'}
      subtitle={'Support our grassroots movement'}
    />

    <section className="section" key="mow-forbiz-graphic">
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <div className="content">
              <h2 className="has-text-centered">Show Your Support</h2>
              <h5 className="has-text-centered">As We Grow</h5>
              <p className="has-text-centered">
                Our grassroots movement is working to have a products page.
                Please pardon the inconvenience we have not completed this
                section yet as we understand the importance of getting this
                website out now. In the meantime, here is a link to RAYGUN’s{' '}
                <a
                  href="https://www.raygunsite.com/collections/mask-of-wellness/"
                  target="_blank"
                  rel="noopener"
                >
                  Mask of Wellness collection
                </a>
                . We’re developing a fund with intention to give a portion of
                proceeds from product sales to families who have lost a
                healthcare worker due to COVID-19.{' '}
                <strong>
                  This endeavor will benefit the community’s wellness.
                </strong>
              </p>
            </div>

            <div class="columns">
              <div class="column">
                <a
                  href="https://www.raygunsite.com/collections/mask-of-wellness/products/mask-of-wellness-mini-sticker-pack"
                  target="_blank"
                  rel="noopener"
                  class="no-border-bottom"
                >
                  <img src={productStickerCircle} />
                </a>
                <a
                  href="https://www.raygunsite.com/collections/mask-of-wellness/products/mask-of-wellness-mini-sticker-pack"
                  target="_blank"
                  rel="noopener"
                >
                  <h5 className="primary">Circle Sticker Pack</h5>
                </a>
                <p>10-Sticker Pack</p>
              </div>

              <div class="column">
                <a
                  href="https://www.raygunsite.com/collections/mask-of-wellness/products/mask-of-wellness-mini-sticker-pack"
                  target="_blank"
                  rel="noopener"
                  class="no-border-bottom"
                >
                  <img src={productWindowCling} />
                </a>
                <a
                  href="https://www.raygunsite.com/collections/mask-of-wellness/products/mask-of-wellness-mini-sticker-pack"
                  target="_blank"
                  rel="noopener"
                >
                  <h5 className="primary">Window Cling</h5>
                </a>
                <p>4x4" Cling</p>
              </div>

              <div class="column">
                <a
                  href="https://www.raygunsite.com/collections/mask-of-wellness/products/smiley-face-mask-t-shirt"
                  target="_blank"
                  rel="noopener"
                  class="no-border-bottom"
                >
                  <img src={productShirt} />
                </a>
                <a
                  href="https://www.raygunsite.com/collections/mask-of-wellness/products/smiley-face-mask-t-shirt"
                  target="_blank"
                  rel="noopener"
                >
                  <h5 className="primary">T-Shirt</h5>
                </a>
                <p>Screen Printed Cotton</p>
              </div>

              <div class="column">
                <a
                  href="https://www.raygunsite.com/collections/mask-of-wellness/products/mask-of-wellness-square-sticker"
                  target="_blank"
                  rel="noopener"
                  class="no-border-bottom"
                >
                  <img src={productWindowCling} />
                </a>
                <a
                  href="https://www.raygunsite.com/collections/mask-of-wellness/products/mask-of-wellness-square-sticker"
                  target="_blank"
                  rel="noopener"
                >
                  <h5 className="primary">Square Sticker</h5>
                </a>
                <p>4x4" Vinyl Sticker</p>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <a
                  href="https://www.raygunsite.com/collections/mask-of-wellness"
                  target="_blank"
                  rel="noopener"
                >
                  <button className="button center-button">
                    View All Products
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default ProductsPage
