import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SecondaryPageHero from '../components/secondaryPageHero'

import forBizHeroImg from '../images/hero-for-businesses-salon.png'
import forBizSvg from '../images/for-businesses.svg'
import benefitsSVG from '../images/benefits-desktop.svg'
import getStartedSVG from '../images/get-started.svg'
import toolkitTeaserImg from '../images/toolkit-teaser@2x.png'

const winWin = (
  <p>
    What's in it for me? This is a <strong>win-win solution</strong>, reducing
    the chance of employees spreading the virus in the workplace and to others.
    In turn, your customers will feel more confident choosing a business that promotes a ‘COVID-Conscious’ work environment. <a href="/get-started">Sign up your business now</a>.
  </p>
)

const ForBusinessesPage = () => (
  <Layout>
    <SecondaryPageHero
      heroImg={forBizHeroImg}
      iconImg={forBizSvg}
      iconImgAlt={'Industrial Building'}
      title={'FOR'}
      accentedTitle={'Businesses'}
      subtitle={
        'Businesses are joining together to improve their community’s wellness'
      }
    />
    <section className="section mow-border-bottom" key="mow-forbiz-graphic">
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">{winWin}</div>
        </div>
        <div className="level">
          <div className="level-item">
            <img src={benefitsSVG} className="business-benefits" />
          </div>
        </div>
      </div>
    </section>
    <section
      className="section mow-border-bottom resources"
      key="mow-forbiz-resources"
    >
      <div className="section-banner">
        <div className="container has-text-centered">
          <div className="section-heading-banner">
            <h3 className="title is-2 has-text-primary">RESOURCES</h3>
            <h5 className="title is-5 has-text-black">
              FREE RESOURCES FOR ALL BUSINESSES
            </h5>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="columns">
          <div className="column">
            <h6>MASKS</h6>
            <div className="content">
              <ul>
                <li>
                  <a
                    href="https://www.cdc.gov/coronavirus/2019-ncov/downloads/DIY-cloth-face-covering-instructions.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Article: DIY Cloth Face Coverings
                  </a>{' '}
                  - CDC
                </li>
                <li>
                  <a
                    href="https://youtu.be/tPx1yqvJgf4"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Video: How to Make Your Own Face Covering
                  </a>{' '}
                  - Surgeon General
                </li>
                <li>
                  <a
                    href="https://www.forbes.com/sites/forbes-personal-shopper/2020/04/20/these-face-masks-can-help-stop-the-spread-of-the-coronavirus-and-theyre-available-right-now/#e666e693936b"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Article: These Masks Can Help Stop The Spread and Are
                    Available Now
                  </a>{' '}
                  - Forbes
                </li>
              </ul>
            </div>
          </div>
          <div className="column">
            <h6>HYGIENE</h6>
            <div className="content">
              <ul>
                <li>
                  <a
                    href="https://www.cdc.gov/coronavirus/2019-ncov/community/disinfecting-building-facility-H.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    PDF: Disinfecting a Building Facility
                  </a>{' '}
                  - CDC
                </li>
                <li>
                  <a
                    href="https://www.epa.gov/pesticide-registration/list-n-disinfectants-use-against-sars-cov-2"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Article: List of Disinfectants to Use for COVID
                  </a>{' '}
                  - EPA
                </li>
                <li>
                  <a
                    href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cleaning-disinfection.html?CDC_AA_refVal=https%3A%2F%2Fwww.cdc.gov%2Fcoronavirus%2F2019-ncov%2Fprepare%2Fcleaning-disinfection.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Article: Cleaning Disinfection
                  </a>{' '}
                  - CDC
                </li>
              </ul>
            </div>
          </div>
          <div className="column">
            <h6>HEALTH</h6>
            <div className="content">
              <ul>
                <li>
                  <a
                    href="https://www.cdc.gov/coronavirus/2019-ncov/downloads/guidance-small-business.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    PDF: Guidance for Small Businesses
                  </a>{' '}
                  - CDC
                </li>
                <li>
                  <a
                    href="https://www1.nyc.gov/assets/doh/downloads/pdf/imm/novel-coronavirus-faq-for-businesses.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    PDF: Coronavirus FAQ for Businesses
                  </a>{' '}
                  - nyc.gov
                </li>
                <li>
                  <a
                    href="https://www.osha.gov/Publications/OSHA3990.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    PDF: Guidance for Preparing Workplaces for COVID-19
                  </a>{' '}
                  - OSHA
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section" key="mow-forbiz-toolkit">
      <div className="section-banner">
        <div className="container">
          <h3 className="title is-2 has-text-centered">
            <span className="text-border-bottom">FREE</span> TOOLKIT
          </h3>
          <h4 className="title is-5 has-text-black has-text-centered">
            SHOW YOUR SUPPORT
          </h4>
        </div>
      </div>

      <div className="container">
        <div className="columns">
          <div className="column">
            <h3 className="title">FOR YOUR BUSINESS</h3>

            <p>
              We’ve created a free downloadable toolkit that you can download
              and print once you’ve registered as a COVID-Conscious business. It
              includes:
            </p>
            <div className="content">
              <ul>
                <li>
                  2 Downloadable business flyers to show customers that you're
                  'COVID-Conscious'
                </li>
                <li>Digital graphics to put on your website</li>
                <li>
                  Posts and hashtags to use in social media and digital
                  marketing
                </li>
              </ul>
            </div>
            <p>
              Registering only takes 1 minute and benefits your business and
              community <b>for a lifetime!</b>
            </p>
            <Link to="/get-started/">
              <button className="button">GET STARTED</button>
            </Link>
          </div>
          <div className="column">
            <img src={toolkitTeaserImg} className="teaser-image" />
          </div>
        </div>
      </div>
    </section>
    <section
      className="section is-paddingless mow-border-top"
      key="mow-forbiz-getstarted"
    >
      <div className="container-fluid">
        <div className="columns footer-cta-section">
          <div className="column is-3 has-background-primary footer-block">
            <img src={getStartedSVG} className="footer-get-started" />
          </div>
          <div className="column is-8 sign-up-col">
            <h5 className="title is-4">SIGN UP YOUR BUSINESS</h5>
            <p>
              Our collaborative efforts WILL play a role in preventing COVID-19 reoccurrences and spikes. Consumers will seek out ‘Covid-Conscious’ Businesses thus benefiting the Health, Wealth, and Wellness of Individuals and Businesses in all of OUR communities.
            </p>
            <Link to="/get-started/">
              <button className="button">JOIN THE COMMUNITY</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default ForBusinessesPage
