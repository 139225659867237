import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SecondaryPageHero from '../components/secondaryPageHero'

import forBizHeroImg from '../images/hero-for-businesses-salon.png'
import forBizSvg from '../images/for-businesses.svg'
import imgSocialDistancing from '../images/chart-social-distancing.svg'

const FaqPage = () => (
  <Layout>
    <SecondaryPageHero
      heroImg={forBizHeroImg}
      iconImg={forBizSvg}
      iconImgAlt={'Industrial Building'}
      title={'Your'}
      accentedTitle={'FAQs'}
      subtitle={"We're here to answer your questions"}
    />
    <section className="section" key="mow-forbiz-graphic">
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <div className="columns anchor-border">
              <div className="column">
                <h5>JUMP TO:</h5>
              </div>
              <div className="column">
                <h5 className="accent">
                  <a href="#masks">Masks</a>
                </h5>
              </div>
              <div className="column">
                <h5 className="accent">
                  <a href="#hygiene">Hygiene</a>
                </h5>
              </div>
              <div className="column">
                <h5 className="accent">
                  <a href="#health">Health</a>
                </h5>
              </div>
              <div className="column">
                <h5 className="accent">
                  <a href="#benefits">Benefits</a>
                </h5>
              </div>
              <div className="column">
                <h5 className="accent">
                  <a href="#ocpa">Duration</a>
                </h5>
              </div>
              <div className="column">
                <h5 className="accent">
                  <a href="#join">Join</a>
                </h5>
              </div>
            </div>
            <p>
              We know that many people have questions and concerns during this
              uncertain time. So we're developing a list of free resources and
              information for our community.
            </p>
            <a className="anchor" name="masks">
              <h2>Masks</h2>
            </a>
            <h5>DIY & Purchasing</h5>
            <div className="content">
              <ul>
                <li>
                  <a
                    href="https://www.cdc.gov/coronavirus/2019-ncov/downloads/DIY-cloth-face-covering-instructions.pdf"
                    target="_blank"
                  >
                    Article: DIY Cloth Face Coverings
                  </a>{' '}
                  - CDC
                </li>
                <li>
                  <a
                    href="https://idph.iowa.gov/Portals/1/userfiles/7/COVID-19%20Guidance%20for%20Cloth%20Face%20Coverings4_6_20.pdf"
                    target="_blank"
                  >
                    PDF: Iowa Dept of Public Health Guidance on Face Coverings -
                    IDPH
                  </a>
                </li>
                <li>
                  <a href="https://youtu.be/tPx1yqvJgf4" target="_blank">
                    Video: How to Make Your Own Face Covering
                  </a>{' '}
                  - Surgeon General
                </li>
                <li>
                  <a
                    href="https://www.forbes.com/sites/forbes-personal-shopper/2020/04/20/these-face-masks-can-help-stop-the-spread-of-the-coronavirus-and-theyre-available-right-now/#e666e693936b"
                    target="_blank"
                  >
                    Article: These Masks Can Help Stop The Spread and Are
                    Available Now
                  </a>{' '}
                  - Forbes
                </li>
              </ul>
            </div>

            <a className="anchor" name="hygiene">
              <h2>Hygiene</h2>
            </a>
            <h5>Resources</h5>
            <div className="content">
              <ul>
                <li>
                  <a
                    href="https://www.cdc.gov/coronavirus/2019-ncov/community/disinfecting-building-facility-H.pdf"
                    target="_blank"
                  >
                    PDF: Disinfecting a Building Facility
                  </a>{' '}
                  - CDC
                </li>
                <li>
                  <a
                    href="https://www.epa.gov/pesticide-registration/list-n-disinfectants-use-against-sars-cov-2"
                    target="_blank"
                  >
                    Article: List of Disinfectants to Use for COVID
                  </a>{' '}
                  - EPA
                </li>
                <li>
                  <a
                    href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cleaning-disinfection.html?CDC_AA_refVal=https%3A%2F%2Fwww.cdc.gov%2Fcoronavirus%2F2019-ncov%2Fprepare%2Fcleaning-disinfection.html"
                    target="_blank"
                  >
                    Article: Cleaning Disinfection
                  </a>{' '}
                  - CDC
                </li>
              </ul>
            </div>
            <br />

            <a className="anchor" name="health">
              <h2>Health</h2>
            </a>
            <h5>Resources</h5>
            <div className="content">
              <ul>
                <li>
                  <a
                    href="https://www.cdc.gov/coronavirus/2019-ncov/downloads/guidance-small-business.pdf"
                    target="_blank"
                  >
                    PDF: Guidance for Small Businesses
                  </a>{' '}
                  - CDC
                </li>
                <li>
                  <a
                    href="https://www1.nyc.gov/assets/doh/downloads/pdf/imm/novel-coronavirus-faq-for-businesses.pdf"
                    target="_blank"
                  >
                    PDF: Coronavirus FAQ for Businesses
                  </a>{' '}
                  - nyc.gov
                </li>
                <li>
                  <a
                    href="https://www.osha.gov/Publications/OSHA3990.pdf"
                    target="_blank"
                  >
                    PDF: Guidance for Preparing Workplaces for COVID-19
                  </a>{' '}
                  - OSHA
                </li>
              </ul>
            </div>

            <a className="anchor" name="benefits">
              <h2>Benefits</h2>
            </a>
            <h5>Of Joining The Community</h5>
            <div className="content">
              <ul>
                <li>
                  The virus often spreads by people who have No symptoms-in Your
                  workplace and potentially to your customers.
                </li>
                <li>
                  A Mask reduces spread by ‘asymptomatic’ and ‘pre-symptomatic’
                  people. We don’t want the silent threat infecting anyone in
                  OUR families.
                </li>
                <li>
                  Consumers often have fear and will select businesses that are
                  ‘Covid-Conscious’
                </li>
                <li>
                  It will improve the physical, emotional, social, and economic
                  well-being of our communities- City or Rural
                </li>
                <li>
                  Society Decides the the course of disease- Lets all do our
                  part.
                </li>
                <li>
                  The virus causing COVID likes to spread to others before
                  symptoms, a major reason this will be long lasting unless we
                  take COVID-Conscious action steps.
                </li>
                <li>
                  We All Will improve the physical and economic well-being of
                  our communities.
                </li>
                <li>This will be temporary</li>
                <li>
                  We must open society in a manner that promotes wellness;
                  socially, physically, and economically.
                </li>
                <div className="chart-social-distancing">
                  <img src={imgSocialDistancing} className="is-centered" />
                  <h5 className="has-text-centered">
                    There is indication that government restrictions will be
                    recurrent unless society changes its patterns. (Kissler-
                    Harvard)
                  </h5>
                </div>
              </ul>
            </div>

            <a className="anchor" name="duration">
              <h2>Duration</h2>
            </a>
            <h5>How Long Do We Follow These Requirements?</h5>
            <div className="content">
              <p>
                Just until CDC provides recommendations that mask wearing is no
                longer needed (found at{' '}
                <a href="https://www.cdc.gov">cdc.gov</a>) or you choose to
                stop.
              </p>
            </div>

            <a className="anchor" name="join">
              <h2>Join</h2>
            </a>
            <h5>But I already follow These Requirements</h5>
            <div className="content">
              <p>
                Thank you!! Now influence those who look up to you by signing up
                and spreading the word instead of ‘spraying’ the word. We
                appreciate your help with advancing this endeavor that benefits
                the Health and Wealth of our Community.
              </p>
            </div>

            <h5>How Do I Join?</h5>
            <div className="content">
              <p>
                Businesses can register as a 'COVID-Conscious' business to be
                entered in our online business directory. Consumers can read
                steps they can take below.
              </p>
              <Link to="/for-businesses">
                <button className="button">For Businesses</button>
              </Link>
              <Link to="/for-consumers">
                <button className="button">For Consumers</button>
              </Link>
            </div>

            <h5>How Else Can I Support The Mask of Wellness Community</h5>
            <div className="content">
              <p>
                Businesses can register as a 'COVID-Conscious' business to be
                entered in our online business directory. Consumers can read
                steps they can take below.
              </p>
              <Link to="/how-to-help">
                <button className="button">Donate</button>
              </Link>
              <Link to="/products">
                <button className="button">Buy Products</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default FaqPage
