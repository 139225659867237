import React from 'react'

import Layout from '../components/layout'
import SecondaryPageHero from '../components/secondaryPageHero'

import forBizHeroImg from '../images/hero-for-businesses-salon.png'
import forBizSvg from '../images/for-businesses.svg'

const Typography = () => (
  <Layout>
    <SecondaryPageHero
      heroImg={forBizHeroImg}
      iconImg={forBizSvg}
      iconImgAlt={'Industrial Building'}
      title={'FOR'}
      accentedTitle={'Businesses'}
      subtitle={
        'Businesses are joining together to improve their community’s wellness'
      }
    />

    <section className="section mow-border-bottom" key="mow-forbiz-graphic">
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <h1>Downloadable Toolkit</h1>
            <p>
              Thank you for becoming a COVID-Conscious business. Please download
              your Mask of Wellness kit to begin displaying and promoting your
              business as a COVID-Conscious business. Give your customers the
              confidence to patronize your business. Thank you for doing your
              part to help our country in a manner that promotes social,
              physical and economic wellness.
            </p>

            <h2>Downloadable Toolkit</h2>
            <p>
              Thank you for becoming a COVID-Conscious business. Please download
              your Mask of Wellness kit to begin displaying and promoting your
              business as a COVID-Conscious business. Give your customers the
              confidence to patronize your business. Thank you for doing your
              part to help our country in a manner that promotes social,
              physical and economic wellness.
            </p>

            <h3>Downloadable Toolkit</h3>
            <p>
              Thank you for becoming a COVID-Conscious business. Please download
              your Mask of Wellness kit to begin displaying and promoting your
              business as a COVID-Conscious business. Give your customers the
              confidence to patronize your business. Thank you for doing your
              part to help our country in a manner that promotes social,
              physical and economic wellness.
            </p>

            <h4>Downloadable Toolkit</h4>
            <p>
              Thank you for becoming a COVID-Conscious business. Please download
              your Mask of Wellness kit to begin displaying and promoting your
              business as a COVID-Conscious business. Give your customers the
              confidence to patronize your business. Thank you for doing your
              part to help our country in a manner that promotes social,
              physical and economic wellness.
            </p>

            <h5>Downloadable Toolkit</h5>
            <p>
              Thank you for becoming a COVID-Conscious business. Please download
              your Mask of Wellness kit to begin displaying and promoting your
              business as a COVID-Conscious business. Give your customers the
              confidence to patronize your business. Thank you for doing your
              part to help our country in a manner that promotes social,
              physical and economic wellness.
            </p>

            <h6>Downloadable Toolkit</h6>
            <p>
              Thank you for becoming a COVID-Conscious business. Please download
              your Mask of Wellness kit to begin displaying and promoting your
              business as a COVID-Conscious business. Give your customers the
              confidence to patronize your business. Thank you for doing your
              part to help our country in a manner that promotes social,
              physical and economic wellness.
            </p>
            <h3 className="title is-2 has-text-white">FOR</h3>
            <h3 className="title is-1 has-text-white is-spaced is-relative">
              <img
                src={forBizSvg}
                style={{
                  transform: 'translateX(-110%)',
                  position: 'absolute',
                  height: '100%',
                }}
                alt="Industrial Building"
              />
              Businesses
            </h3>
            <h4 className="subtitle is-4 has-text-white">
              Businesses are joining together to improve their community’s
              wellness
            </h4>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default Typography
