import React, { useState } from 'react'
import { navigate } from 'gatsby'

import addToMailchimp from 'gatsby-plugin-mailchimp'
import { useFormik } from 'formik'
import { mailchimpCountries, mailchimpBusinessTypes } from '../utils/utils'

const MailChimpForm = () => {
  const [message, setMessage] = useState('')
  const formik = useFormik({
    initialValues: {
      EMAIL: '',
      FNAME: '',
      LNAME: '',
      BIZNAME: '',
      BIZADDR: {
        addr1: '',
        addr2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
      },
      PHONE: '',
      BIZTYPE: '',
    },
    // https://maskofwellness.us4.list-manage.com/subscribe/post?u=66a2d815c0886c682e4afc276&amp;id=055b1e3643"
    onSubmit: values => {
      const addrValues = Object.keys(values.BIZADDR).reduce((memo, key) => {
        memo[`BIZADDR[${key}]`] = values.BIZADDR[key]
        return memo
      }, {})
      let mailchimpSubmitData = {
        ...values,
        ...addrValues,
        // b_66a2d815c0886c682e4afc276_055b1e3643: values.honeypot,
      }
      delete mailchimpSubmitData.BIZADDR
      addToMailchimp(values.EMAIL, mailchimpSubmitData)
        .then(data => {
          console.log(data)
          if (data.result === 'error') {
            setMessage(data.msg)
          } else {
            // setMessage('Thank you, you are not a part of the solution that benefits the health and wellness of our comunity');
          }
          setMessage(data.msg)
          navigate('/toolkit/')
        })
        .catch(() => {
          setMessage('Error submitting')
        })
    },
  })
  return (
    <form onSubmit={formik.handleSubmit} className="submit-form">
      <div id="mc_embed_signup">
        <div id="mc_embed_signup_scroll">
          <h2 className="has-text-centered">Self-Certification</h2>
          <h5 className="has-text-centered">For COVID-Conscious Businesses</h5>
          {/*<div className="indicates-required">
            <span className="asterisk">*</span> indicates required
          </div>*/}

          <div className="field-body">
              <div className="field">
                <label className="label sr-only" htmlFor="mce-FNAME">
                  First Name{' '}
                </label>
                <div className="control">
                  <input
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values['mce-FNAME']}
                    name="FNAME"
                    className="input"
                    id="mce-FNAME"
                    placeholder="First Name"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label sr-only" htmlFor="mce-LNAME">
                  Last Name{' '}
                </label>
                <div className="control">
                  <input
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values['mce-LNAME']}
                    name="LNAME"
                    className="input"
                    id="mce-LNAME"
                    placeholder="Last Name"
                  />
                </div>
              </div>
           </div>

           <div className="field-body">
              <div className="field">
                <label className="label sr-only" htmlFor="mce-BIZNAME">
                  Business Name{' '}
                </label>
                <div className="control">
                  <input
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values['mce-BIZNAME']}
                    name="BIZNAME"
                    className="input"
                    id="mce-BIZNAME"
                    placeholder="Business Name"
                  />
                </div>
              </div>
          </div>
          <div className="field-body">
              <div className="field">
                <label className="label sr-only" htmlFor="mce-BIZADDR-addr1">
                  Business Physical Address{' '}
                </label>
                <div className="control">
                  <input
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values['mce-BIZADDR-addr1']}
                    maxLength="70"
                    name="BIZADDR[addr1]"
                    id="mce-BIZADDR-addr1"
                    className="input"
                    placeholder="Business Physical Address"
                  />
                </div>
              </div>
              <div className="field">
                <label className="label sr-only" htmlFor="mce-BIZADDR-addr2">
                  Address Line 2
                </label>
                <div className="control">
                  <input
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values['mce-BIZADDR-addr2']}
                    maxLength="70"
                    name="BIZADDR[addr2]"
                    id="mce-BIZADDR-addr2"
                    className="input"
                    placeholder="Address Line 2"
                  />
                </div>
              </div>
           </div>


              <div className="field-body">
                <div className="field">
                  <label className="label sr-only" htmlFor="mce-BIZADDR-city">
                    City
                  </label>
                  <div className="control">
                    <input
                      onChange={formik.handleChange}
                      type="text"
                      value={formik.values['mce-BIZADDR-city']}
                      maxLength="40"
                      name="BIZADDR[city]"
                      id="mce-BIZADDR-city"
                      className="input"
                      placeholder="City"
                    />
                </div>
              </div>
              <div className="field">
                <label className="label sr-only" htmlFor="mce-BIZADDR-state">
                  State/Province/Region
                </label>
                <div className="control">
                  <input
                    onChange={formik.handleChange}
                    type="text"
                    value={formik.values['mce-BIZADDR-state']}
                    maxLength="20"
                    name="BIZADDR[state]"
                    id="mce-BIZADDR-state"
                    className="input"
                    placeholder="State/Province/Region"
                  />
                </div>
              </div>
            </div>



            <div className="columns">
              <div className="column">
                <div className="field">
                  <label className="label sr-only" htmlFor="mce-BIZADDR-zip">
                    Postal / Zip Code
                  </label>
                  <div className="control">
                    <input
                      onChange={formik.handleChange}
                      type="text"
                      value={formik.values['']}
                      maxLength="10"
                      name="BIZADDR[zip]"
                      id="mce-BIZADDR-zip"
                      className="input"
                      placeholder="Zip Code"
                    />
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <label className="label sr-only" htmlFor="mce-BIZADDR-country">
                    Country
                  </label>
                  <select
                    name="BIZADDR[country]"
                    id="mce-BIZADDR-country"
                    onChange={formik.handleChange}
                    value={formik.values['mce-BIZADDR-country']}
                    className="input"
                    placeholder="Country"
                  >
                    {mailchimpCountries.map(x => (
                      <option key={x.id} value={x.id}>
                        {x.name}
                      </option>
                    ))}
                  </select>
                </div>
            </div>
          </div>

          <div className="columns">
            <div className="column">
              <div className="field">
                <label className="label sr-only" htmlFor="mce-PHONE">
                  Phone Number{' '}
                </label>
                <div className="control">
                  <input
                    onChange={formik.handleChange}
                    type="text"
                    name="PHONE"
                    className="input"
                    value={formik.values['mce-PHONE']}
                    id="mce-PHONE"
                    placeholder="Phone"
                  />
                </div>
              </div>
            </div>
            <div className="column">
              <div className="field">
                <label className="label sr-only" htmlFor="mce-EMAIL">
                  Email Address <span className="asterisk">*</span>
                </label>
                <div className="control">
                  <input
                    onChange={formik.handleChange}
                    type="email"
                    value={formik.values['mce-EMAIL']}
                    name="EMAIL"
                    className="input"
                    id="mce-EMAIL"
                    placeholder="Email Address"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="field">
            <label className="label sr-only" htmlFor="mce-BIZTYPE">
              Business Type{' '}
            </label>
            <select
              name="BIZTYPE"
              className="input"
              id="mce-BIZTYPE"
              onChange={formik.handleChange}
              value={formik.values['mce-BIZTYPE']}
            >
              {mailchimpBusinessTypes.map(x => (
                <option key={x.id} value={x.id}>
                  {x.id}
                </option>
              ))}
            </select>
          </div>

          <label class="checkbox" required>
            <input type="checkbox" />
            I self-certify that my business will strive to achieve the following:
            <ul>
              <li><b>Masks</b> - Worn by every employee in all areas of the work environment.</li>
              <li><b>Hygiene</b> - Hand and surface hygiene practiced by ALL employees in conjunction with physical distancing when possible.</li>
              <li><b>Health</b> - Employee attesting daily- no primary COVID-19 symptoms.</li>
            </ul>
          </label>

          <label class="checkbox">
            <input type="checkbox" required/>
            I also agree to the <a href="/terms-and-conditions">terms and conditions</a>.
          </label>

          {/* <div className="field input-group">
          <strong>Are you a business or consumer? </strong>
          <ul>
            <li>
              <input onChange={formik.handleChange} type="checkbox" value="1" name="group[69772][1]" id="mce-group[69772]-69772-0">
              <label className="label sr-only" htmlFor="mce-group[69772]-69772-0">Business</label>
            </li>
            <li>
              <input onChange={formik.handleChange} type="checkbox" value="2" name="group[69772][2]" id="mce-group[69772]-69772-1">
              <label className="label sr-only" htmlFor="mce-group[69772]-69772-1">Consumer</label>
            </li>
          </ul>
        </div> */}
          <div id="mce-responses" className="clear">
            <div
              className="response"
              id="mce-error-response"
              style={{ display: 'none' }}
            />
            <div
              className="response"
              id="mce-success-response"
              style={{ display: 'none' }}
            />
          </div>
          {/* real people should not fill this in and expect good things - do not remove this or risk form bot signups*/}
          <div
            style={{ position: 'absolute', left: '-5000px' }}
            aria-hidden="true"
          >
            {/* <input
              onChange={formik.handleChange}
              type="text"
              name="b_66a2d815c0886c682e4afc276_055b1e3643"
              tabindex="-1"
              value={formik.values[""]}
            /> */}
            <div className="clear"></div>
          </div>
          <button
            type="button"
            value="Subscribe"
            name="subscribe"
            id="mc-embedded-subscribe"
            onClick={formik.handleSubmit}
            className="button center-button"
          >
            Submit
          </button>
        </div>
      </div>

      <div dangerouslySetInnerHTML={{ __html: message }} />
    </form>
  )
}

export default MailChimpForm
