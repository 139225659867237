import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SecondaryPageHero from '../components/secondaryPageHero'

import forBizHeroImg from '../images/hero-for-businesses-salon.png'
import forBizSvg from '../images/for-businesses.svg'
import imgSocialDistancing from '../images/chart-social-distancing.png'
import imgSocialDistancingProtective from '../images/chart-social-distancing-with-protective-measures.png'

const ForConsumersPage = () => (
  <Layout>
    <SecondaryPageHero
      heroImg={forBizHeroImg}
      iconImg={forBizSvg}
      iconImgAlt={'Industrial Building'}
      title={'FOR'}
      accentedTitle={'Consumers'}
      subtitle={
        'Consumers are supporting businesses that improve their community’s wellness'
      }
    />
    <section className="section mow-border-bottom" key="mow-forbiz-graphic">
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <p>
              Our collaborative efforts WILL play a role in preventing COVID-19
              reoccurrences and spikes. Appreciation, YOU are fostering the
              health and the wealth of all of OUR communities.{' '}
              <b>We are all in this together.</b>
            </p>
            <h2>Online Directory</h2>
            <h5 className="title is-5 has-text-black">Coming Soon!</h5>
            <p>
              We are building a searchable online business directory so you can
              search for COVID-Conscious businesses.
            </p>
            <h2>Benefits</h2>
            <h5 className="title is-5 has-text-black">For All of Us</h5>
            <ul>
              <li>
                The virus often spreads by people who have No symptoms-in Your
                workplace and potentially to your customers.
              </li>
              <li>
                A Mask reduces spread by ‘asymptomatic’ and ‘pre-symptomatic’
                people. We don’t want the silent threat infecting anyone in OUR
                families.
              </li>
              <li>
                Consumers often have fear and will select businesses that are
                ‘Covid-Conscious’
              </li>
              <li>
                It will improve the physical, emotional, social, and economic
                well-being of our communities- City or Rural
              </li>
              <li>
                The virus causing COVID likes to spread to others before
                symptoms, a major reason this will be long lasting unless we
                take COVID-Conscious action steps.
              </li>
              <li>
                We All Will improve the physical and economic well-being of our
                communities.
              </li>
              <li>This will be temporary</li>
              <li>
                We must open society in a manner that promotes wellness;
                socially, physically, and economically.
              </li>
              <div className="chart-social-distancing has-text-centered">
                <h3>We the people</h3>
                <h6>Decide the course of disease - Let's all do our part</h6>
                <div className="columns">
                  <div className="column">
                    <img src={imgSocialDistancing} className="is-centered" />
                    <p className="caption">Yellow represents strict government restrictions, opening society back up and repeating the cycle.</p>
                  </div>
                  <div className="column">
                    <img
                      src={imgSocialDistancingProtective}
                      className="is-centered"
                    />
                  <p className="caption">Following Mask of Wellness safety guidelines opening society in a Covid Conscious manner. </p>
                  </div>
                </div>
                {/*}<p>
                  <strong>
                    There is indication that government restrictions will be
                    recurrent unless society changes its patterns.
                    (Kissler-Harvard)
                  </strong>
                </p>*/}
              </div>
            </ul>

            <h3>How You Can Help</h3>
            <p>
              We are working quickly to build a searchable online directory for
              consumers to search for businesses who are 'COVID-Conscious'. If
              you'd like to support our cause, please visit{' '}
              <Link to="/how-to-help">How to Help</Link>.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="section resources" key="mow-forbiz-resources">
      <div className="section-banner">
        <div className="container has-text-centered">
          <div className="section-heading-banner">
            <h3 className="title is-2 has-text-primary">RESOURCES</h3>
            <h5 className="title is-5 has-text-black">FOR OUR COMMUNITY</h5>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="columns">
          <div className="column">
            <h6>MASKS</h6>
            <div className="content">
              <ul>
                <li>
                  <a
                    href="https://www.cdc.gov/coronavirus/2019-ncov/downloads/DIY-cloth-face-covering-instructions.pdf"
                    target="_blank"
                  >
                    Article: DIY Cloth Face Coverings
                  </a>{' '}
                  - CDC
                </li>
                <li>
                  <a
                    href="https://idph.iowa.gov/Portals/1/userfiles/7/COVID-19%20Guidance%20for%20Cloth%20Face%20Coverings4_6_20.pdf"
                    target="_blank"
                  >
                    PDF: Iowa Dept of Public Health Guidance on Face Coverings -
                    IDPH
                  </a>
                </li>
                <li>
                  <a href="https://youtu.be/tPx1yqvJgf4" target="_blank">
                    Video: How to Make Your Own Face Covering
                  </a>{' '}
                  - Surgeon General
                </li>
                <li>
                  <a
                    href="https://www.forbes.com/sites/forbes-personal-shopper/2020/04/20/these-face-masks-can-help-stop-the-spread-of-the-coronavirus-and-theyre-available-right-now/#e666e693936b"
                    target="_blank"
                  >
                    Article: These Masks Can Help Stop The Spread and Are
                    Available Now
                  </a>{' '}
                  - Forbes
                </li>
                <li>
                  <strong>Coming soon:</strong>We are working on adding links so
                  you can puchase masks.
                </li>
              </ul>
            </div>
          </div>
          <div className="column">
            <h6>HYGIENE</h6>
            <div className="content">
              <ul>
                <li>
                  <a
                    href="https://www.cdc.gov/coronavirus/2019-ncov/community/disinfecting-building-facility-H.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    PDF: Disinfecting a Building Facility
                  </a>{' '}
                  - CDC
                </li>
                <li>
                  <a
                    href="https://www.epa.gov/pesticide-registration/list-n-disinfectants-use-against-sars-cov-2"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Article: List of Disinfectants to Use for COVID
                  </a>{' '}
                  - EPA
                </li>
                <li>
                  <a
                    href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/cleaning-disinfection.html?CDC_AA_refVal=https%3A%2F%2Fwww.cdc.gov%2Fcoronavirus%2F2019-ncov%2Fprepare%2Fcleaning-disinfection.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Article: Cleaning Disinfection
                  </a>{' '}
                  - CDC
                </li>
              </ul>
            </div>
          </div>
          <div className="column">
            <h6>HEALTH</h6>
            <div className="content">
              <ul>
                <li>
                  <a
                    href="https://www.cdc.gov/coronavirus/2019-ncov/downloads/guidance-small-business.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    PDF: Guidance for Small Businesses
                  </a>{' '}
                  - CDC
                </li>
                <li>
                  <a
                    href="https://www1.nyc.gov/assets/doh/downloads/pdf/imm/novel-coronavirus-faq-for-businesses.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    PDF: Coronavirus FAQ for Businesses
                  </a>{' '}
                  - nyc.gov
                </li>
                <li>
                  <a
                    href="https://www.osha.gov/Publications/OSHA3990.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    PDF: Guidance for Preparing Workplaces for COVID-19
                  </a>{' '}
                  - OSHA
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default ForConsumersPage
