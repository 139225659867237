import React from 'react'
// import { FaTwitter, FaGithub, FaMedium } from 'react-icons/fa'
import { Link } from 'gatsby'
import { StaticQuery, graphql } from 'gatsby'
import '../styles/styles.scss'
// import Emoji from './emoji'

import socialInstagram from '../images/social-instagram.svg'
import socialTwitter from '../images/social-twitter.svg'
import socialFacebook from '../images/social-facebook.svg'

const Footer = () => (
  <StaticQuery
    query={graphql`
      query SocialQuery {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `}
    render={data => (
      <footer className="footer center">
        <div className="level">
          <div className="level-item left-align-level">
            <div>
              <p>&copy;11:11, LLC</p>
            </div>
          </div>
          <div className="level-item center-column">
            <div>
              <Link to="/terms-and-conditions/">Terms & Conditions</Link> |{' '}
              <Link to="/faq/">FAQ</Link>
            </div>
          </div>
          <div className="level-item right-align-level">
            <div>
              <a
                href="https://www.instagram.com/covidconsciousbusinesses/"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-social-icon"
              >
                <img src={socialInstagram} alt="Instagram logo" />
              </a>
              <a
                href="https://twitter.com/covidconscious"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-social-icon"
              >
                <img src={socialTwitter} alt="Twitter logo" />
              </a>
              <a
                href="https://www.facebook.com/covidconsciousbusinesses"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-social-icon"
              >
                <img src={socialFacebook} alt="Facebook logo" />
              </a>
            </div>
          </div>
        </div>
      </footer>
    )}
  />
)

export default Footer
