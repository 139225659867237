import React from 'react'

import Layout from '../components/layout'
import SecondaryPageHero from '../components/secondaryPageHero'

import forBizHeroImg from '../images/hero-for-businesses-salon.png'
import forBizSvg from '../images/for-businesses.svg'

const faqPage = () => (
  <Layout>
    <SecondaryPageHero
      heroImg={forBizHeroImg}
      iconImg={forBizSvg}
      iconImgAlt={'Industrial Building'}
      title={'Our'}
      accentedTitle={'Terms'}
      subtitle={"We're here to answer your questions"}
    />

    <section className="section" key="mow-forbiz-graphic">
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <div className="content">
              <h2>Terms & Conditions</h2>
              <h5>UPDATED May 1, 2020</h5>
              <p>
                You agree to follow the CDC and your state’s Department of Public Health guidelines. If you are found to be noncompliant with those guidelines, you are required to (and agree to) remove all of your Mask of Wellness symbols and products from your business. Mask of Wellness recognizes the CDC and Department of Public Health guidelines do not guarantee complete protection against COVID-19 to your employees and customers. If an individual becomes sick, contact a doctor. By using the Mask of Wellness website or self-certifying as a COVID-Conscious business, you acknowledge and represent that you have read these terms, understand them, and agree to abide them voluntarily as your own free act and deed, including without limitation the Release of Liability and Indemnification requirements.
              </p>

              <h5>Idemnification</h5>
              <p>
                You agree to indemnify and hold The Mask of Wellness parties
                harmless from any claim or demand, including reasonable
                attorney's fees and costs, made by any third party due to or
                arising out of your use of the Site in a manner not permitted by
                these Terms, including without limitation your actual or alleged
                violation of these Terms, or infringement of a third party’s
                intellectual property or other rights by you or another user of
                our Site using your computer, mobile device or account.
              </p>
              <h5>Changes to These Terms</h5>
              <p>
                We reserve the right, at any time, to modify, alter, or update
                these Terms at any time without prior notice. You are encouraged
                to check this page regularly for changes to the Terms.
                Modifications will become effective immediately upon being
                posted to our Site, without further notice to you. Your
                continued use of any of our Site after such modifications are
                posted constitutes your acknowledgement and acceptance of such
                modifications, and you may not amend these Terms.
              </p>
              <h5>Contact Us</h5>
              <p>
                If you have any questions about these Terms, please feel free to
                contact us as follows:
              </p>
              <p>
                The Mask of Wellness
                <br />
                <a href="mailto:maskofwellness@gmail.com">
                  maskofwellness@gmail.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default faqPage
