import React, { useState } from 'react'
import { Link } from 'gatsby'
import classnames from 'classnames'
// import { FaGithub } from 'react-icons/fa';

import '../styles/styles.scss'
// import gatsbyLogo from '../images/gatsby-icon.png';
// import bulmaLogo from '../images/bulma-logo.png';
import mowLogo from '../images/mow-for-community-wellness.svg'

const Navbar = () => {
  const [isMobilenavOpen, setIsMobileNavOpen] = useState(false)
  return (
    <React.Fragment>
      <nav
        className="navbar is-spaced is-fixed-top"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <a className="navbar-item no-border-bottom" href="/">
            <img src={mowLogo} width="50" height="50" />
            <h2 className="title is-4">MASK OF WELLNESS</h2>
          </a>

          <a
            role="button"
            className={classnames('navbar-burger burger no-border-bottom', {
              'is-active': isMobilenavOpen,
            })}
            onClick={() => setIsMobileNavOpen(!isMobilenavOpen)}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div
          id="navbarBasicExample"
          className={classnames('navbar-menu', {
            'is-active': isMobilenavOpen,
          })}
        >
          {/* <div className="navbar-start"></div> */}

          <div className="navbar-end">
            <Link to="/for-businesses/" className="navbar-item">
              Businesses
            </Link>
            <Link to="/for-consumers/" className="navbar-item">
              Consumers
            </Link>
            <Link to="/how-to-help/" className="navbar-item">
              How to Help
            </Link>
            <Link to="/our-story/" className="navbar-item">
              Our Story
            </Link>
            <Link to="/products/" className="navbar-item">
              Products
            </Link>
            <Link to="/gallery/" className="navbar-item">
              Gallery
            </Link>
            <Link to="/contact-us/" className="navbar-item">
              Contact
            </Link>

            {/* <div className="navbar-item has-dropdown is-hoverable">
							<a className="navbar-link">More</a>
							<div className="navbar-dropdown">
								<a className="navbar-item">About</a>
								<a className="navbar-item">Jobs</a>
								<a className="navbar-item">Contact</a>
								<hr className="navbar-divider" />
								<a className="navbar-item">Report an issue</a>
							</div>
						</div> */}
            <div className="navbar-item">
              <div className="buttons">
                <Link className="button" to="/get-started/">
                  <strong>Get Started</strong>
                </Link>
                {/* <a className="button is-light">Log in</a> */}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </React.Fragment>
  )
}

export default Navbar
