import React from 'react'

import Layout from '../components/layout'
import Midsection from '../components/midsection'

import mowHeroVideowebm from '../video/mask-of-wellness-video.webm'
import mowHeroVideo from '../video/mask-of-wellness-video.mp4'

const IndexPage = () => (
  <Layout>
    <section className="hero gradientBg is-fullheight-with-navbar">
      <div className="hero-body is-mow-border mow-hero">
        <div className="hero-overlay"></div>
        <video
          playsInline="playsinline"
          autoPlay="autoplay"
          muted="muted"
          loop="loop"
          className="hero-video"
        >
          <source src={mowHeroVideowebm} type="video/webm" />
          <source src={mowHeroVideo} type="video/mp4" />
          <p>This browser does not support the video element.</p>
        </video>
        <div className="container">
          <div className="columns">
            <div className="column is-one-third is-offset-two-thirds">
              <div className="container">
                <h1 className="title is-4">BE A PART OF</h1>
                <h1 className="title is-1 is-spaced cursive">
                  The Community<span className="period"></span>
                </h1>
                <h4 className="subtitle is-4 is-hidden-mobile">
                  Businesses are joining together for the wellness of their
                  communities
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div className="">
      <h4 className="subtitle is-4 is-hidden-tablet mobile-hero-subtitle">
        Businesses are joining together for the wellness of their communities.
      </h4>
    </div>
    <Midsection />
  </Layout>
)

export default IndexPage
