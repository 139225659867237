import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import SecondaryPageHero from '../components/secondaryPageHero'

import forBizHeroImg from '../images/hero-for-businesses-salon.png'
import forBizSvg from '../images/for-businesses.svg'
import toolkitTeaserImg from '../images/toolkit-teaser@2x.png'
import iconInstagramCircle from '../images/icon-instagram-circle.svg'
import iconTwitterCircle from '../images/icon-twitter-circle.svg'
import iconFacebookCircle from '../images/icon-facebook-circle.svg'

const Toolkit = () => (
  <Layout>
    <SecondaryPageHero
      heroImg={forBizHeroImg}
      iconImg={forBizSvg}
      iconImgAlt={'Industrial Building'}
      title={'Free'}
      accentedTitle={'Toolkit'}
      subtitle={
        'Businesses are joining together to improve their community’s wellness'
      }
    />

    <section className="section" key="mow-forbiz-toolkit">
      <div className="container">
        <div className="columns">
          <div className="column">
            <h3 className="title">Free Toolkit</h3>

            <p>
              Thank you! We’ve created a free downloadable toolkit that you can
              download since you're now registered as a COVID-Conscious
              business! It includes:
            </p>
            <div className="content">
              <h5>Printable Business Flyers</h5>
              <p>
                Hang near your entryway to show customers that you're
                'COVID-Conscious'
              </p>
              <ul>
                <li>
                  <a
                    href="../downloads/horizontal-flyer.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    11x8.5in Horizontal flyer with icons
                  </a>
                </li>
                <li>
                  <a
                    href="../downloads/vertical-flyer.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    8.5x11in Vertical text-based flyer
                  </a>
                </li>
              </ul>
              <h5>Digital Graphics for Your Website</h5>
              <p>
                Upload and display on your website, social media, and in digital
                marketing.
              </p>
              <ul>
                <li>
                  <a
                    href="../downloads/logo-no-text.png"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Square Mask logo without text
                  </a>
                </li>
                <li>
                  <a
                    href="../downloads/logo-with-text.png"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Square Mask logo with text
                  </a>
                </li>
                <li>
                  <a
                    href="../downloads/logo-with-text-and-border.png"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Square Mask logo with text and border
                  </a>
                </li>
                <li>
                  <a
                    href="../downloads/logo-horizontal-with-text.png"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Horizontal Mask logo with text and border
                  </a>
                </li>
              </ul>
              <h3 className="title">Social Media</h3>
              <h5>Posts to Share</h5>
              <p>
                "We're proud to join #themaskofwellness community! Check out our
                website to learn how we're changing to stay open responsibly
                during #COVID19."
              </p>
              <p>
                "We're taking a positive approach promoting responsible behavior
                necessary to keep society open during #COVID19 while benefiting
                social, physical, and economic wellness."
              </p>
              <h5>Follow Us</h5>
              <a
                href="https://www.instagram.com/covidconsciousbusinesses/"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-social-icon"
              >
                <img alt="Instagram logo" src={iconInstagramCircle} />
              </a>
              <a
                href="https://twitter.com/covidconscious"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-social-icon"
              >
                <img alt="Twitter logo" src={iconTwitterCircle} />
              </a>
              <a
                href="https://www.facebook.com/covidconsciousbusinesses"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-social-icon"
              >
                <img alt="Facebook logo" src={iconFacebookCircle} />
              </a>
              <p>
                <span className="accent">#</span>themaskofwellness
                <br />
                <span className="accent">#</span>covidconsciousbusiness
                <br />
                <span className="accent">#</span>covid-consciousbusiness
                <br />
                <span className="accent">#</span>maskofwellnessbusiness
                <br />
              </p>
              <h3 className="title">Products</h3>
              <p>
                If you'd like to purchase stickers, window clings, and t-shirts
                see our <Link to="/products/">products page</Link>.
              </p>
            </div>
          </div>
          <div className="column">
            <img src={toolkitTeaserImg} className="teaser-image" />
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default Toolkit
